.notify{
  position: fixed;
  top:0;
  left:0;
  right:0;
  z-index: 100000;
  background: red;
  text-align: center;
  color:white;
  font-weight: 'Bold';
}
.css-13cymwt-control{
  background-color: transparent!important;
  border: 0px!important;
  color:black!important
}
.css-1nmdiq5-menu{    
  color:black!important
}