:root{
    --main-color:#2DD449
}

.app-sidebar-logo-default{
    /* background-color: var(--main-color)!important; */
    padding-top:10px;
    padding-bottom:10px;
    object-fit: contain;
    border-radius: 10px;
}
.login-wrapper{
    background-color: var(--main-color)!important;
}
.login-card{
    background-color: white!important;
    width:30%;
    margin:auto
}
.login-text{
    color: black!important;
}
.login-btn{
    background-color: var(--main-color)!important;
}
.login-btn:hover,.login-btn:focus,.login-btn:focus-within{
    background-color: white!important;
    border:1px solid var(--main-color)!important ;
    color: var(--main-color)!important;
}
.login-input,.login-input:focus,.login-input:focus-visible{
    background-color: white!important;
    color: black!important;
}
@media(max-width:768px){
    .login-card{
        width:100%
    }
    
}